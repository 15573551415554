<template>
  <div class="page">
    <div 
        class="tab"
        :style="{
          left: drawer ? '324px' : '0px'
        }"
    >
        <el-tabs v-model="radio1" type="card" @tab-click="getConnect" v-if="heddinList">
            <el-tab-pane label="我的气象站" name="1">
                <span slot="label">
                  <div style="display: flex; width: 80px; justify-content: center;">
                    <div style="margin-top: 4px; margin-right: 5px">
                      <img src="@/assets/homeIcon/my_active.png" style="width: 20px;" alt="" v-if="radio1 === '1'">
                      <img src="@/assets/homeIcon/my.png" style="width: 20px;" alt="" v-else>
                    </div>
                    <div>
                      我的
                    </div>
                  </div>
                </span>
                <!-- 列表组件 -->
                <div>
                  <homeDeviceList :list="allData"></homeDeviceList>
                </div>
                
            </el-tab-pane>
            <el-tab-pane label="全部气象站" name="0">
              <span slot="label">
                <div style="display: flex; width: 80px; justify-content: center;">
                  <div style="margin-top: 4px; margin-right: 5px">
                    <img src="@/assets/homeIcon/all_active.png" style="width: 20px;" alt="" v-if="radio1 === '0'">
                    <img src="@/assets/homeIcon/all.png" style="width: 20px;" alt="" v-else>
                  </div>
                  <div>
                    全部
                  </div>
                </div>
              </span>
               <!-- 列表组件 -->
                <div>
                  <homeDeviceList :list="allData"></homeDeviceList>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
    <!-- 显示或隐藏设备列表 -->
     <div class="show_icon"
        :style="{
          left: getLeft()
        }"
        @click="heddinList = !heddinList"
     >
        <img v-if="heddinList" src="@/assets/homeIcon/hidden.png" style="width: 20px;" alt="">
        <img v-else src="@/assets/homeIcon/show.png" style="width: 20px;" alt="">
     </div>
    <!-- 检索 -->
    <div class="search">
      <el-input
          class="asss"
          placeholder="请输入气象站名称"
          v-model="device_name"
          clearable
      />
      <div @click="handleSearch">
        <span></span>
      </div>
    </div>
    <div class="data_list">
        <div
            v-for="(item, index) in icons"
            :key="index"
            @click="menuClick(item.dataFunc, index)"
            :class="{selected:activeIndex === index}"
        >
            <div class="titles">{{ item.name }}</div>
            <img :src="item.url"/>
        </div>
    </div>

    <el-drawer
        ref="drawer"
        :visible.sync="drawer"
        :size="324"
        direction="ltr"
        :destroy-on-close="true"
        :wrapperClosable="false"
        :modal="false"
        :with-header="false"
        class="details"
    >
      <div class="mainlist">
        <div class="box1">
          <i @click="clDrawer()">X</i>
          <div class="butt" 
            style="background-color: green; width: 100%; padding: 20px;"
            :style="{backgroundColor: deviceData.status === '1' ? 'green' : '#ccc'}"
          >
            <h1>{{ deviceData.deviceName }} <span v-if="deviceData.status === '0'">(离线)</span></h1>
            <h2>{{ deviceData.deviceType }}</h2>
            <p>纬度:{{ deviceData.positionLat }},经度:{{ deviceData.positionLng }}</p>
          </div>
        </div>
        <div class="box4"
          :style="{background : deviceData.status === '1' ? 'linear-gradient( 180deg, #8263D5 0%, #491CC1 100%)' : '#c2c2c2'}"
        >
          <div style="width: 100%; height: 100%;  background-image:url('../../assets/gis/device_bg.png'); background-size: 100% 100%;">
            <div style="display: flex; font-size: 12px; justify-content: space-between; padding:10px 20px 0 5px;">
              <div>本次数据更新时间</div>
              <div>{{ deviceData.newTime }} {{ deviceData.week }}</div>
            </div>
            <div class="wendu">
                <p>{{ deviceData.tempOut ? deviceData.tempOut : '--' }}°C</p>
            </div>
            <div style="font-size: 16px; text-align: center; margin-right: 30px; margin-top: 10px; cursor: pointer;"  @click="choseDetails()">
                点击查看站点更多信息>
            </div>
          </div>
        </div>

        <div class="box2">
          <div class="postlist">
            <ul>
              <li>
                <div>
                    <img src="../../assets/gis/outdoor-temperature.png" alt="">
                    <span>{{ deviceData.tempOut ? deviceData.tempOut : '--' }}℃</span>
                    <span style="font-size: 14px;">室外温度</span>
                </div>
                <div>
                    <img src="../../assets/gis/outdoor-humidity.png" alt="">
                    <span>{{ deviceData.humidityOut ? deviceData.humidityOut : '--' }}%</span>
                    <span style="font-size: 14px;">室外湿度</span>
                </div>
               
              </li>
            </ul>
          </div>

          
          <div class="postlist">
            <ul>
              <li>
                <div>
                    <img src="../../assets/gis/wind.png" alt="">
                    <span>{{ deviceData.windSpeed ? deviceData.windSpeed : '--' }}m/s</span>
                    <span style="font-size: 14px;">平均风速(2分钟)</span>
                </div>
                <div>
                    <img src="../../assets/gis/wind.png" alt="">
                    <span>{{ deviceData.hpaAbs ? deviceData.hpaAbs : '--' }}hPa</span>
                    <span style="font-size: 14px;">气压值</span>
                </div>
              </li>
            </ul>
          </div>
          <div class="postlist">
            <ul>
              <li>
                <div>
                    <img src="../../assets/gis/rain.png" alt="">
                    <span>{{ deviceData.hourRain }}mm</span>
                    <span style="font-size: 14px;">时降雨量(距整点)</span>
                </div>
                <div>
                    <img src="../../assets/gis/hpa.png" alt="">
                    <span>{{ deviceData.windDir ? deviceData.windDir : '--' }}({{ windDegreeToDir(deviceData.windDir) }})</span>
                    <span style="font-size: 14px;">平均风向(2分钟)</span>
                </div>
              </li>
            </ul>
          </div>
          <div class="postlist">
            <ul>
              <li>
                <div>
                    <img src="../../assets/gis/indoor_tem.png" alt="">
                    <span>{{ deviceData.tempIn ? deviceData.tempIn : '--' }}℃</span>
                    <span style="font-size: 14px;">室内温度</span>
                </div>
                <div>
                    <img src="../../assets/gis/indoor_hum.png" alt="">
                    <span>{{ deviceData.humidityIn ? deviceData.humidityIn : '--' }}%</span>
                    <span style="font-size: 14px;">室内温度</span>
                </div>
              </li>
            </ul>
          </div>
          <div class="postlist">
            <ul>
              <li>
                <div>
                    <img src="../../assets/gis/uv.png" alt="">
                    <span>{{ deviceData.uv }}</span>
                    <span style="font-size: 14px;">UV(紫外线强度)</span>
                </div>
                <div>
                    <img src="../../assets/gis/sun.png" alt="">
                    <span>{{ deviceData.solarRadiation ? deviceData.solarRadiation : '--' }}W/㎡</span> 
                    <span style="font-size: 14px;">光照强度</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </el-drawer>
    <div 
        class="sebiao" 
        v-if="activeIndex === 1 || activeIndex === 2 || activeIndex === 3 || activeIndex === 5 || activeIndex === 6"
    >
        <div style="color: #000; display: flex; padding: 5px;justify-content: space-between;">
            <div style="font-size: 16px;margin-top: 2px; color: #1181E5;">
                {{ 
                    activeIndex === 1 ? '温度(°C)' : activeIndex === 2 ? '湿度(%)' :  activeIndex === 3 ? '风速(m/s)' : activeIndex === 5 ? '降水(mm)' : '光照'
                }}
            </div> 
        </div>
        <!-- 色阶item -->
        <!-- 温度色标 -->
        <div style="color: #000; margin-left: 5px; display: flex;" v-if="activeIndex === 1"> 
            <div v-for="(item,index) in tem" :key="index">
                <div :style="{backgroundColor : item.color,width:'33px',height:'23px'}"></div>
                <div style="font-size: 16px;margin-left: -40px; text-align: center;margin-top: 5px;">
                    {{ item.scope }} 
                </div>
            </div>
        </div>
        <!-- 湿度色标 -->
        <div style="color: #000; margin-left: 5px; display: flex;" v-else-if="activeIndex === 2"> 
            <div v-for="(item,index) in rhu" :key="index">
                <div :style="{backgroundColor : item.color,width:'59px',height:'18px'}"></div>
                <div style="font-size: 16px;margin-left: 15px; height: 28px; text-align: center; margin-left: -50px;">
                    {{ item.scope }}
                </div>
            </div>
        </div>
        <!-- 风速色阶 -->
        <div style="color: #000; margin-left: 5px;display: flex;" v-else-if="activeIndex === 3"> 
            <div v-for="(item,index) in wind" :key="index">
                <div :style="{backgroundColor : item.color,width:'54px',height:'16px'}"></div>
                <div style="font-size: 16px;margin-left: 15px; height: 26px; line-height: 36px; text-align: center; margin-left: -40px;">
                    {{ item.scope }}
                </div>
            </div>
        </div>
        <!-- 降水色标 -->
        <div 
            style="color: #000; margin-left: 5px; display: flex;" 
            v-else-if="activeIndex === 5"> 
            <div v-for="(item,index) in pre" :key="index">
                <div 
                    :style="{
                        backgroundColor : item.color,
                        width:'65px',
                        height:'20px',
                        border: item.color === '#ffffff' ? '1px solid #000' : ''
                    }"
                >
                </div>
                <div style="font-size: 16px;">
                    {{ item.scope }}
                </div>
            </div>
        </div>
        <!-- 光照色标 -->
        <div 
            style="color: #000; margin-left: 5px; display: flex;" 
            v-else-if="activeIndex === 6"> 
            <div v-for="(item,index) in sun" :key="index">
                <div 
                    :style="{
                        backgroundColor : item.color,
                        width:'60px',
                        height:'20px',
                        border: item.color === '#ffffff' ? '1px solid #000' : ''
                    }"
                >
                </div>
                <div style="font-size: 16px;">
                    {{ item.scope }}
                </div>
            </div>
        </div>
    </div>
    <el-amap 
        id="amapDemo"
        :center="center"
        :zoom="zoom"
        :plugin="plugins"
      
    >
      <el-amap-marker
          v-for="(item,index) in marker"
          :key="index"
          :position="item.position"
          :content="item.content"
          :events="item.events"
          :offset="item.offset"
      >
      </el-amap-marker>
    </el-amap>
  </div>
</template>

<script>
import homeDeviceList from "./components/homeDeviceList.vue";
import $cookies from "vue-cookies";
import dayjs from "dayjs";
import {$device} from "@/services";
import { windDegreeToDir } from "@/utils/wind";
import {tem,rhu,pre,prs,wind,sun} from '@/utils/colorLevel.js'
let emptyObj = (param) => {
  if (!param) {
    return {}
  }
  return param
}
let icons = [
  {
    name: '状态',
    dataFunc: {
      getVal: (item) => {
        let val = item['status']
        if (val == 1) {
          return '在线';
        }
        return '离线';
      },
      color: (va) => {
        if (va == '在线') {
          return '#00BB00';
        }
        return '#cccccc';
      }
    },
    url: require('@/assets/svg/gis-home-box2-list.svg')
  },
  {
    name: '温度',
    dataFunc: {
      getVal: (item) => {
        return emptyObj(item['deviceStatData'])['tempOut']
      },
      color: (va) => {
        if (va < -32) {
          return '#83007f';
        } else if (va > -32 && va <= -28) {
          return '#002D8E';
        } else if (va > -28 && va <= -24) {
          return '#185BAE';
        } else if (va > -24 && va <= -20) {
          return '#2272D1';
        } else if (va > -20 && va <= -16) {
          return '#35A2F4';
        } else if (va > -16 && va <= -12) {
          return '#75D1F6';
        } else if (va > -12 && va <= -8) {
          return '#97E1F1';
        }  else if (va > -8 && va <= -4) {
          return '#BFF7FF';
        } else if (va > -4 && va <= 0) {
          return '#F3FCFC';
        } else if (va > 0 && va <= 4) {
          return '#DCFEDA';
        } else if (va > 4 && va <= 8) {
          return '#C2FFB7';
        } else if (va > 8 && va <= 12) {
          return '#BBFE7D';	
        } else if (va > 12 && va <= 16) {
          return '#FDFCA0';	
        } else if (va > 16 && va <= 20) {
          return '#FFF3BF';	
        } else if (va > 20 && va <= 24) {
          return '#FEDD9F';	
        } else if (va > 24 && va <= 28) {
          return '#FFAC6F';	
        } else if (va > 28 && va <= 32) {
          return '#FC8784';	
        }  else {
          return '#E85D5F';
        }
      }
    },
    url: require('@/assets/svg/gis-home-box2-1.svg')
  },
  {
    name: '湿度',
    dataFunc: {
      getVal: (item) => {
        return emptyObj(item['deviceStatData'])['humidityOut']
      },
      color: (va) => {
        if (va <= 10) {
          return '#d1fdca';
        } else if (va > 10 && va <= 20) {
          return '#a9fa9e';
        } else if (va > 20 && va <= 30) {
          return '#89ec80';
        } else if (va > 30 && va <= 40) {
          return '#6bdc67';
        } else if (va > 40 && va <= 50) {
          return '#50d34d';
        } else if (va > 50 && va <= 60) { 
          return '#3acc38';
        } else if (va > 60 && va <= 70) {
          return '#2cc22b';
        } else if (va > 70 && va <= 80) {
          return '#26b426';
        } else if (va < 80 && va <= 90) {
          return '#22a8aa';
        } else if (va < 90 && va <= 100) {
          return '#1ea420';
        }  else {
          return '#ffffff';
        }
      }
    },
    url: require('@/assets/svg/gis-home-box2-2.svg')
  },
  {
    name: '风力',
    dataFunc: {
      getVal: (item) => {
        return emptyObj(item['deviceStatData'])['windSpeed']
      },
      color: (va) => {
        if (va >= 0 && va <= 2) {
          return '#bbfb98';
        } else if (va > 2 && va <= 4) {
          return '#5df708';
        } else if (va > 4 && va <= 6) {
          return '#d2fd09';
        } else if (va > 6 && va <= 8) {
          return '#fff90c';
        } else if (va > 8 && va <= 10) {
          return '#ffc601';
        } else if (va > 10 && va <= 12) {
          return '#f8870a';
        } else if (va > 12 && va <= 14) {
          return '#fb4805';
        } else if (va > 14 && va <= 16) {
          return '#fa0203';
        } else if (va > 16 && va <= 18) {
          return '#c55a01';
        } else if (va > 18 && va <= 20) {
          return '#7a0301';
        } else if (va > 20) {
          return '#712b00';
        }
      }
    },
    url: require('@/assets/svg/gis-home-box2-3.svg')
  },
  {
    name: '风向',
    dataFunc: {
      getVal: (item) => {
        if (emptyObj(item['deviceStatData'])['windDir']) {
          return item.deviceStatData.windDir
        } else {
          return emptyObj(item['deviceStatData'])['windDir']
        }
      },
      color: (va) => {
        if (va > 22.5 && va <= 67.5) {
          return '#83ef12';
        } else if (va <= 112.5) {
          return '#83ef12';
        } else if (va <= 157.5) {
          return '#83ef12';
        } else if (va <= 202.5) {
          return '#83ef12';
        } else if (va <= 247.5) {
          return '#83ef12';
        } else if (va <= 292.5) {
          return '#83ef12';
        } else if (va <= 337.5) {
          return '#83ef12';
        } else if (va <= 22.5 && va > 337.5) {
          return '#83ef12';
        } else {
          return '#fff';
        }  
      }
    },
    url: require('@/assets/svg/gis-home-box2-4.svg')
  },
  {
    name: '降雨',
    dataFunc: {
      getVal: (item) => {
        return emptyObj(item['deviceStatData'])['hourRain']
      },
      color: (va) => {
        if (va <= 0) {
          return '#ffffff';
        } else if (va > 0 && va < 2) {
          return '#91f18e';
        } else if (va >= 2 && va < 4) {
          return '#12a42e';
        } else if (va >= 4 && va < 6) {
          return '#57b6f9';
        } else if (va >= 6 && va < 8) {
          return '#2429eb';
        } else if (va >= 8 && va < 10) {
          return '#005f43';
        } else if (va >= 10 && va < 20) {
          return '#f600ee';
        } else if (va >= 20 && va < 50  ) {
          return '#b9325b';
        } else if (va >= 50 && va < 100 ) {
          return '#f22e1c';
        } else if (va >= 100 ) {
          return '#6d000a';
        } 
      }
    },
    url: require('@/assets/svg/gis-home-box2-5.svg')
  },
  {
    name: '光照',
    dataFunc: {
      getVal: (item) => {
        return emptyObj(item['deviceStatData'])['solarRadiation']
      },
      color: (wd) => {
        if (wd <= 200) {
          return '#666';
        } else if (wd <= 400) {
          return '#71a0cd';
        } else if (wd <= 600) {
          return '#1f68b5';
        } else if (wd <= 800) {
          return '#017596';
        } else if (wd <= 1000) {
          return '#00b469';
        } else if (wd <= 1200) {
          return '#6fb031';
        } else if (wd <= 1400) {
          return '#b7c939';
        } else if (wd <= 1600) {
          return '#c29d46';
        } else if (wd <= 1800) {
          return '#ba5844';
        } else {
          return '#f72e00';
        }
      }
    },
    url: require('@/assets/svg/gis-home-box2-6.svg')
  },
  {
    name: 'uv',
    dataFunc: {
      getVal: (item) => {
        return emptyObj(item['deviceStatData'])['uv']
      },
      color: (va) => {
        if (va > -30 && va <= -15) {
          return '#fff';
        } else if (va <= 5) {
          return '#1f68b5';
        } else if (va <= 15) {
          return '#017596';
        } else if (va <= 30) {
          return '#00b469';
        } else if (va <= 40) {
          return '#6fb031';
        } else if (va <= 50) {
          return '#f72e00';
        } else {
          return '#666'; 
        }
      }
    },
    url: require('@/assets/svg/gis-home-box2-7.svg')
  }, 
]
export default {
  name: "GIS",
  components: {
    homeDeviceList
  },
  data() {
    return {
      tem,rhu,pre,prs,wind,sun,
      radio1:'0',
      setDisabled: {
        disabledDate(time) {
          return time.getTime() > Date.now();  // 可选历史天、可选当前天、不可选未来天
          // return time.getTime() > Date.now() - 8.64e7;  // 可选历史天、不可选当前天、不可选未来天
          // return time.getTime() < Date.now() - 8.64e7;  // 不可选历史天、可选当前天、可选未来天
          // return time.getTime() < Date.now(); // 不可选历史天、不可选当前天、可选未来天
        },
      },
      drawer: false,
      heddinList: false,
      timestamp: dayjs().valueOf(),
      sliderDate: 144,
      center: [116.40, 39.90],
      zoom: 10,
      plugins: [
        {
          pName: 'ToolBar',
          liteStyle: 'true',
          position: 'RB',
          offset: [6, 150] //偏移量 x,y轴
        },
        {
          pName: 'Geolocation',
          buttonPosition: 'RB',
        },
        {
          pName: 'Scale',
        },
      ],
      activeIndex: 1,
      marker: [],
      deviceData: {},
      startTime: dayjs().subtract(2, "m").format("YYYYMMDDHHmm").concat("00"),
      endTime: dayjs().format("YYYYMMDDHHmm").concat("00"),
      device_name: "",//搜索框输入值
      marker_status: "tempf",//状态值取值
      dataFunc: icons[1].dataFunc,
      timeSdata: '',
      icons,
      drawerdevice:'',
      allData:[],
      showList:false
    };
  },
  created() {
    this.getConnect()
    this.maindatatime()
    setInterval(() => {
      this.getConnect()
      if (this.drawer) {
        let obj = this.allData.find(item => item.device === this.drawerdevice)
        this.getLatestData(obj)
      }
    }, 13000)
  },
  destroyed() {
    clearInterval(this.timeSdata)
  },
  methods: {
    windDegreeToDir,
    maindatatime() {
      this.timeSdata = dayjs().format("YYYY-MM-DD HH:mm:ss")
    },
    formatTooltip(val) {
      let time = this.timestamp
      // 0-24 1 600000
      if (val !== 144) {
        time = time - ((144 - val) * 600000)//10分钟
      }
      this.startTime = dayjs(time).format("YYYYMMDDHHmm").concat("00")
      this.endTime = dayjs(time).subtract(-1, "m").format("YYYYMMDDHHmm").concat("00")
      return dayjs(time).format('DD日HH:mm')
    },
    getConnectSite() {
      this.getConnect();
      if (!this.deviceData.device) return;
      let {device, device_name, device_type, lng, lat, currentUserName} = this.deviceData
      this.getLatestData(device, device_name, device_type, lng, lat, currentUserName);
    },
    // 获取设备信息及数据
    async getConnect() {
      let params = {}
      if (this.radio1 === '1') {
        if (!$cookies.get("userInfo")) {
          this.$message.error('请登录')
          return
        }
        params.userId = $cookies.get("userInfo").id
        params.userName = $cookies.get("userInfo").userName
        params.email = $cookies.get("userInfo").email
      } else {
        params.userId = ''
        params.userName = ''
        params.email = ''
      }
      $device.getDeviceList1(params).then(res => {
        if (res.code === 200) {
          res.data = res.data.sort((a,b) => b.status - a.status)
          this.allData = res.data
          this.handleSearch()
        } else {
          this.$message.error('系统异常,请联系管理员')
        }
      })

    },
    getLatestData(item) {
      item.deviceStatData.positionLat = item.positionLat
      item.deviceStatData.positionLng = item.positionLng
      this.deviceData = item.deviceStatData
      this.deviceData.status = item.status
      this.deviceData.deviceName = item.deviceName
      this.deviceData.deviceType = item.deviceType
      if (this.deviceData.time) {
        this.deviceData.time = this.deviceData.time + ''
        this.deviceData.newTime = this.deviceData.time.slice(0,4) + '-' + this.deviceData.time.slice(4,6) + '-' + this.deviceData.time.slice(6,8) + ' ' +this.deviceData.time.slice(8,10) + ':' + this.deviceData.time.slice(10,12) + ':' +  this.deviceData.time.slice(12,14)
        item.deviceStatData.week = this.getWeek(item.deviceStatData.time)
      } else {
        this.deviceData.newTime = ''
        item.deviceStatData.week = ''
      }
      this.center = [item.position_lng, item.position_lat]
      this.drawer = true
    },
    clDrawer() {
      this.drawer = false
    },
    menuClick(func, index) {
      this.activeIndex = index
      this.dataFunc = func
      this.getConnect()
    },
    getWeek(value) {
      let datas = dayjs(value).day()
      let week = ['日', '一', '二', '三', '四', '五', '六']
      return '星期' + week[datas]
    },
    choseDetails() {
      this.$router.push({
        name: "site",
        query: {
          device: this.deviceData.device,
        }
      });
    },
    handleSearch() {
      const that = this
      let arr = []
      for (let i = 0 ; i < this.allData.length; i++ ) {
          if (this.allData[i].deviceName.indexOf(this.device_name) >= 0) {
              arr.push(this.allData[i])
          }
      }
      arr.sort((a,b) => a.status - b.status)
      let markers = []
      arr.forEach(item => {
        let itemVal = that.dataFunc.getVal(item);
        if (this.activeIndex === 0 || item.status == 1) {
          markers.push({
            position: [item.positionLng, item.positionLat],
            content: `<div style-"position: relative;">
                  <span style="position: absolute;width:100%;text-align: center;top:10px;color:${this.activeIndex === 6 ? '#fff' : '#000'};font-size:14px">${item.status == 0 ? '离线' : itemVal ? itemVal : '--'}</span>
                  <svg t="1681371234176" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="32455" width="50" height="50">
                    <path d="M509.8496 256c-68.7104 0-124.416 57.309867-124.416 128 0 70.690133 55.7056 128 124.416 128 68.744533 0 124.450133-57.309867 124.450133-128 0-70.690133-55.7056-128-124.450133-128z m0-256C303.684267 0 136.533333 171.9296 136.533333 384S509.8496 1024 509.8496 1024s373.3504-427.9296 373.3504-640C883.2 171.9296 716.049067 0 509.8496 0z"
                      fill="${ item.status === '1' ? itemVal ? this.dataFunc.color(itemVal) : '#c2c2c2' : '#ccc'}" p-id="32456"></path>
                    <path d="M512 358.4m-290.133333 0a290.133333 290.133333 0 1 0 580.266666 0 290.133333 290.133333 0 1 0-580.266666 0Z"
                      fill="${ item.status === '1' ? itemVal ? this.dataFunc.color(itemVal) : '#c2c2c2' : '#ccc'}" p-id="32457"></path>
                  </svg>
                </div>`,
            offset: [-15, -30],
            events: {
              click() {
                that.getLatestData(item)
                that.drawerdevice = item.device
              }
            }
          })
        } else {
          markers.push({
            position: [item.positionLng, item.positionLat],
            content: `<div style-"position: relative;">
                  <span style="position: absolute;width:100%;text-align: center;top:10px;color:#000;font-size:12px"></span>
                  <svg t="1681371234176" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="32455" width="30" height="30">
                    <path d="M509.8496 256c-68.7104 0-124.416 57.309867-124.416 128 0 70.690133 55.7056 128 124.416 128 68.744533 0 124.450133-57.309867 124.450133-128 0-70.690133-55.7056-128-124.450133-128z m0-256C303.684267 0 136.533333 171.9296 136.533333 384S509.8496 1024 509.8496 1024s373.3504-427.9296 373.3504-640C883.2 171.9296 716.049067 0 509.8496 0z"
                      fill="${ item.status === '1' ? itemVal ? this.dataFunc.color(itemVal) : '#c2c2c2' : '#ccc'}" p-id="32456"></path>
                    <path d="M512 358.4m-290.133333 0a290.133333 290.133333 0 1 0 580.266666 0 290.133333 290.133333 0 1 0-580.266666 0Z"
                      fill="${ item.status === '1' ? itemVal ? this.dataFunc.color(itemVal) : '#c2c2c2' : '#ccc'}" p-id="32457"></path>
                  </svg>
                </div>`,
            offset: [-15, -30],
            events: {
              click() {
                that.getLatestData(item)
                that.drawerdevice = item.device
              }
            }
          })
        }
      })
      this.marker = markers
    },
    // 动态获取左偏移
    getLeft() {
      if (this.drawer) {
        if (!this.heddinList) {
          return '324px'
        } else {
          return '567px'
        }
      } else {
        if (!this.heddinList) {
          return '0px'
        } else {
          return '243px'
        }
      }
    }
  },
};

</script>
<style lang="less" scoped>
:deep(.el-slider__stop) {
  background-color: #000 !important;
}
:deep(.el-radio-button__inner) { 
    background-color: #fff;
}
.single-line {
  white-space: nowrap;      /* 保持文本在一行，不换行 */
  overflow: hidden;         /* 超出容器部分隐藏 */
  text-overflow: ellipsis;  /* 超出部分显示为省略号 */
}
.page {
  width: 100%;
  height: calc(100vh - 50px);
  position: relative;

  .details {
    width: 324px;
    height: 100vh;

    > :nth-child(1) {
      > :nth-child(1) {
        overflow-y: auto;
      }
    }
  }

  .search {
    display: flex;
    position: absolute;
    z-index: 99;
    top: 20px;
    right: 90px;
    width: 300px;

    .asss {
      width: 300px;
    }

    input {
      border: none;
      outline: none;
      height: 40px;
      border-radius: 3px 0 0 3px;
      box-sizing: border-box;
      width: 0;
      font-size: 12px;
      padding: 2px 10px;
      width: 220px;
    }

    div {
      margin-left: 5px;
      height: 40px;
      width: 60px;
      background-color: #fff;
      border-radius: 3px;
      overflow: hidden;

      span {
        background-color: #038eb1a6;
        display: block;
        height: 100%;
        background-image: url('../../assets/svg/gis-sidbar-search-A.svg');
        background-size: 60%;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }
  .tab {
    height: 100vh;
    position: absolute;
    background: #fff;
    z-index: 100;
  }
  .show_icon {
    height: 100%;
    position: absolute;
    z-index: 100;
    top: 50%;
  }
  // .sList {
  //     position: absolute;
  //     z-index: 100;
  //     width: 241px;
  //     height: 315px;
  //     background: #F8F8F8;
  //     border-radius: 6px 6px 6px 6px;
  //     border: 1px solid #C5C5C5;
  //     top: 40.5%;
  //     right: 70px;
  //     box-sizing: border-box;
  //     overflow: auto;
  //     .list_item {
  //       width: 217px;
  //       height: 90px;
  //       border-radius: 12px 5px 12px 12px;
  //       margin: 0 auto;
  //       margin-top: 10px;
  //       background: #fff;
  //       padding: 10px;
  //       box-sizing: border-box;
  //       .dot_online {
  //         width: 9px;
  //         height: 9px;
  //         background: #2CAE51;
  //         border-radius: 50%;
  //       }
  //       .dot_offline {
  //         width: 9px;
  //         height: 9px;
  //         background: #D7DED9;
  //         border-radius: 50%;
  //         margin-top: 3px;
  //       }
  //     }
  // }
  .data_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    align-content: center;
    top: 20px;
    right: 20px;
    z-index: 999;
    .selected {
      border: 3px solid #08b;
      background-color: #ccc;
    }

    div {
      cursor: pointer;
      margin-bottom: 2px;
      width: 40px;
      height: 40px;
      background-color: rgba(255, 0255, 255, 0.9);
      border: 1px solid #ccc;
      text-align: center;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .titles {
        position: absolute;
        top: 0;
        left: -85px;
        width: 80px;
        line-height: 40px;
        border: none;
        background-color: transparent;
        display: none;
        background-image: url('../../assets/gis/jiabiao.png');
        background-size: 100% 100%;
        color: #666;
      }

      img {
        width: 30px;
        height: 30px;
        margin: 5px auto;
      }
    }

    :nth-child(1) {
      display: block;
    }
  }

  .data_list:hover {
    > div:hover {
      background-color: #ccc;
    }
  }

  .mainlist {
    font-size: 14px;
    color: #fff;
    box-sizing: border-box;
    width: 324px;
    display: flex;
    flex-direction: column;
    position: relative;

    .box1 {
      flex-shrink: 0;
      width: 100%;
      height: 200px;
      background-image: url('../../assets/gis-personal/bg.png');
      background-size: 100% 100%;
      display: flex;
      align-items: flex-end;
      position: relative;
      text-shadow: 1px 1px 1px rgb(0 0 0 / 50%);
      i {
        position: absolute;
        top: 20px;
        right: 20px;
        display: block;
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        cursor: pointer;
      }

      i:hover {
        border: 1px solid #666;
      }

      h1 {
        font-size: 28px;
        margin-bottom: 12px;
      }

      h2 {
        font-size: 18px;
        margin-bottom: 10px;
      }

      p {
        font-size: 85%;
        margin: 2px 0;
      }

      img { 
        height: 50px;
        vertical-align: baseline;
        margin-right: 10px;
      }
    }

    .box2 {
      width: 100%;
      display: flex;
      flex-direction: column;
      color: #666;
      justify-content: space-between;
      padding-top: 10px;
      padding-left: 10px;
      .postlist {
        width: 300px;
        height: 110px;
        padding: 5px;
        box-shadow: #6666663f 1px 1px 10px 1px;
        margin-bottom: 10px;
        margin-right: 10px;
        color: #666666;
        ul {
          li {
            display: flex;
            padding: 5px 0;
            div {
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              img {
                width: 50px;
              }
              :nth-child(1) {
                width: 50px;
                height: 50px;
              }

              :nth-child(2) {
                font-size: 18px;
                margin: 5px 0;
              }

              :nth-child(3) {
                font-size: 12px;
              }
            }
          }
        }
      }

      .list {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        border: 1px solid #666;
        border-radius: 5px;

        .left {
          display: flex;
          align-content: center;
          justify-content: center;
          padding-left: 10px;

          img {
            width: 50px;
          }

          .MeteorologicalElements {
            line-height: 50px;
            font-size: 14px;
          }
        }

        .right {
          padding-right: 10px;

          span {
            margin-left: 5px;
          }
        }
      }
    }

    .box3 {
      padding-bottom: 50px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      span {
        cursor: pointer;
        color: #fff;
        background-color: #08b;
        border-radius: 5px;
        width: 60%;
        font-size: 18px;
        padding: 11px 0;
      }
    }

    .box4 {
      width: 100%;
      height: 110px;
      flex-shrink: 0;
      text-shadow: 1px 1px 1px rgb(0 0 0 / 50%);

      .top {
        display: flex;
        flex-direction: column;
       
      }
      .wendu {
        display: flex;
        justify-content: center;
        margin-right: 30px;
        margin-top: 10px;
        p {
          margin-top: 10px;
          font-size: 33px;
          display: flex;

          span {
            font-size: 30px;
          }
        }
      }
      .cer {
        display: flex;
        justify-content: center;

        p {
          margin: 10px 10px;
        }
      }
    }
  }

  .block {
    padding: 10px 30px;
    z-index: 100;
    position: absolute;
    bottom: 50px;
    left: calc(40% - 350px);
    background-color: #7c7c7c65;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;

    .aaa {
      background-color: #491cc2;
    }

    > :nth-child(1) {
      width: 1000px;
      margin-right: 20px;
    }

    > :nth-child(2) {
      position: absolute;
      right: 90px;
      top: 8px;
      width: 50px;
      height: 40px;
      overflow: hidden;
      cursor: pointer;
      background-color: #fff;
      background-image: url(../../assets/gis-personal/gis-list-data.png);
      background-size: 60%;
      background-position: center center;
      background-repeat: no-repeat;

      > :nth-child(1) {
        width: 50px;
        cursor: pointer;
        opacity: 0;

        :nth-child(1) {
          width: 50px;
        }
      }

      > :nth-child(1):focus {
        border: none;
      }
    }

    > :nth-child(2) :focus {
      border: 1px solid #08b;
    }
  }

  .data_list > div:hover > .titles {
    display: block;
  }
}
.sebiao {
    background: #FFFFFF;
    border-radius: 6px;
    margin-left: 10px;
    height:80px;width:606px;
    position: absolute;
    right: 80px;
    bottom: 20px;
    z-index:999;
}

</style>